import React, { useState } from 'react';
import { Gift, Mail, User, MessageSquare, ArrowRight, X, DollarSign } from 'lucide-react';
import { voucherService } from '@/services/api/vouchers';
import { paymentService } from '@/services/api/payments';
import { useAuth } from '@/hooks/useAuth';
import { StripePaymentForm } from '@/components/StripePaymentForm';
import toast from 'react-hot-toast';

interface GiftVoucherModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function GiftVoucherModal({ isOpen, onClose }: GiftVoucherModalProps) {
  const { user } = useAuth();
  const [amount, setAmount] = useState<number>(100);
  const [recipientName, setRecipientName] = useState('');
  const [recipientEmail, setRecipientEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState<{ clientSecret: string; id: string } | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const predefinedAmounts = [100, 250, 500, 1000, 2500, 5000];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) return;

    if (amount < 100) {
      toast.error('Minimum gift amount is $100');
      return;
    }

    setIsSubmitting(true);
    try {
      // Create payment intent for voucher
      const response = await paymentService.createPaymentIntent({
        amount,
        propertyId: 'voucher', // Special identifier for vouchers
        method: 'stripe',
        recipientName,
        recipientEmail,
        message
      });

      if (!response.success || !response.data?.clientSecret) {
        throw new Error(response.message || 'Failed to create payment intent');
      }

      setPaymentIntent(response.data);
      setShowPaymentForm(true);
    } catch (error) {
      console.error('Submit error:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to process request');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePaymentSuccess = () => {
    toast.success('Gift voucher sent successfully!');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={onClose}></div>
      <div className="relative bg-white rounded-2xl shadow-xl w-full max-w-lg mx-4 animate-fade-in">
        {/* Header */}
        <div className="p-6 border-b border-gray-200">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-primary-50 rounded-lg">
                <Gift className="w-6 h-6 text-primary-600" />
              </div>
              <div>
                <h3 className="text-xl font-semibold">Send Gift Voucher</h3>
                <p className="text-sm text-gray-600 mt-1">Share the joy of investing</p>
              </div>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Content */}
        <div className="p-6 max-h-[calc(100vh-200px)] overflow-y-auto">
          {!showPaymentForm ? (
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Amount Selection */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Gift Amount
                </label>
                <div className="grid grid-cols-3 gap-2 mb-3">
                  {predefinedAmounts.map((value) => (
                    <button
                      key={value}
                      type="button"
                      onClick={() => setAmount(value)}
                      className={`p-2 rounded-lg border text-sm transition-colors ${
                        amount === value
                          ? 'border-primary-500 bg-primary-50 text-primary-700 font-medium'
                          : 'border-gray-200 hover:border-primary-300 text-gray-700'
                      }`}
                    >
                      ${value.toLocaleString()}
                    </button>
                  ))}
                </div>
                <div className="relative">
                  <DollarSign className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
                  <input
                    type="number"
                    min="100"
                    step="100"
                    value={amount}
                    onChange={(e) => setAmount(Number(e.target.value))}
                    className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                    placeholder="Enter custom amount"
                  />
                </div>
                <p className="mt-1 text-sm text-gray-500">Minimum amount: $100</p>
              </div>

              {/* Recipient Details */}
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Recipient's Name
                  </label>
                  <div className="relative">
                    <User className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
                    <input
                      type="text"
                      value={recipientName}
                      onChange={(e) => setRecipientName(e.target.value)}
                      className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                      placeholder="Enter name"
                      required
                    />
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">
                    Recipient's Email
                  </label>
                  <div className="relative">
                    <Mail className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
                    <input
                      type="email"
                      value={recipientEmail}
                      onChange={(e) => setRecipientEmail(e.target.value)}
                      className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                      placeholder="Enter email"
                      required
                    />
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Personal Message (Optional)
                </label>
                <div className="relative">
                  <MessageSquare className="absolute left-3 top-3 w-5 h-5 text-gray-400" />
                  <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
                    placeholder="Add a personal message..."
                    rows={3}
                  />
                </div>
              </div>

              <div className="bg-primary-50 p-4 rounded-lg">
                <h4 className="font-medium text-primary-900 mb-2">Gift Voucher Details</h4>
                <div className="grid grid-cols-2 gap-4 text-sm text-primary-700">
                  <div>
                    <ul className="space-y-2">
                      <li className="flex items-center gap-2">
                        <div className="w-1 h-1 bg-primary-500 rounded-full"></div>
                        Valid for 12 months
                      </li>
                      <li className="flex items-center gap-2">
                        <div className="w-1 h-1 bg-primary-500 rounded-full"></div>
                        Any property investment
                      </li>
                    </ul>
                  </div>
                  <div>
                    <ul className="space-y-2">
                      <li className="flex items-center gap-2">
                        <div className="w-1 h-1 bg-primary-500 rounded-full"></div>
                        Email instructions sent
                      </li>
                      <li className="flex items-center gap-2">
                        <div className="w-1 h-1 bg-primary-500 rounded-full"></div>
                        Non-refundable
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                disabled={isSubmitting}
                className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors disabled:opacity-50"
              >
                {isSubmitting ? (
                  <>
                    <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                    Processing...
                  </>
                ) : (
                  <>
                    Continue to Payment
                    <ArrowRight className="w-5 h-5" />
                  </>
                )}
              </button>
            </form>
          ) : (
            paymentIntent && (
              <StripePaymentForm
                amount={amount}
                onSuccess={handlePaymentSuccess}
                onCancel={() => setShowPaymentForm(false)}
                clientSecret={paymentIntent.clientSecret}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
}