import api from '../axios';
import type { ApiResponse } from '@/types/response';

interface CryptoPaymentResponse {
  txHash: string;
  blockNumber: number;
  confirmations: number;
  receivedAmount: number;
}

interface TransactionVerification {
  verified: boolean;
  actualAmount?: number;
}

export const cryptoService = {
  async processCryptoPayment(
    amount: number,
    chainId: number,
    propertyId?: string
  ): Promise<ApiResponse<CryptoPaymentResponse>> {
    try {
      const { data } = await api.post<ApiResponse<CryptoPaymentResponse>>('/payments/crypto', {
        amount,
        chainId,
        propertyId
      });
      return data;
    } catch (error) {
      console.error('Process crypto payment error:', error);
      throw error;
    }
  },

  async verifyTransaction(
    txHash: string,
    expectedUsdAmount: number,
    chainId: number
  ): Promise<ApiResponse<TransactionVerification>> {
    try {
      const { data } = await api.post<ApiResponse<TransactionVerification>>('/payments/verify-transaction', {
        txHash,
        expectedUsdAmount,
        chainId
      });
      return data;
    } catch (error) {
      console.error('Verify transaction error:', error);
      throw error;
    }
  }
};