import React, { useState, useEffect } from 'react';
import { Trophy, Star, Award, Shield, Gift } from 'lucide-react';
import { calculateBadgeProgress } from '@/utils/badge-calculations';
import { investmentService } from '@/services/api/investments';
import { referralService } from '@/services/api/referrals';
import toast from 'react-hot-toast';

interface BadgeProgress {
  type: 'silver' | 'gold' | 'platinum';
  progress: number;
  isUnlocked: boolean;
  unlockedAt: string | null;
  requirements: {
    minInvestment: number;
    monthlyInvestments: number;
    minPortfolioValue: number;
    referrals?: number;
    minReturn?: number;
  };
  requirementsMet: string[];
}

const BADGE_ICONS = {
  silver: Trophy,
  gold: Star,
  platinum: Award
} as const;

export function BadgesSection() {
  const [badgesProgress, setBadgesProgress] = useState<BadgeProgress[]>([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const fetchBadgesProgress = async () => {
      try {
        // Fetch all required data
        const [investmentsResponse, referralResponse] = await Promise.all([
          investmentService.getInvestments(),
          referralService.getReferralHistory()
        ]);

        if (!investmentsResponse.success || !referralResponse.success) {
          throw new Error('Failed to fetch required data');
        }

        // Calculate badge progress
        const progress = calculateBadgeProgress(
          investmentsResponse.data,
          referralResponse.data?.completed || 0
        );

        setBadgesProgress(progress);
      } catch (error) {
        console.error('Failed to load badges progress:', error);
        toast.error('Failed to load badges progress');
      } finally {
        setIsLoading(false);
      }
    };

    fetchBadgesProgress();
  }, []);

  const formatProgress = (progress: number) => {
    return Number(progress).toFixed(1);
  };

  if (isLoading) {
    return (
      <div className="p-6">
        <div className="animate-pulse space-y-8">
          {[1, 2, 3].map((i) => (
            <div key={i} className="h-48 bg-gray-200 rounded-xl"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-2">Investor Badges</h3>
        <p className="text-gray-600">
          Earn badges by maintaining consistent investment activity and growing your portfolio.
        </p>
      </div>

      <div className="grid gap-8">
        {badgesProgress.map((badge) => (
          <div
            key={badge.type}
            className={`rounded-xl border ${
              badge.isUnlocked
                ? 'border-primary-200 bg-primary-50/50'
                : 'border-gray-200'
            }`}
          >
            <div className="p-6">
              <div className="flex items-start gap-4">
                <div className={`p-3 rounded-xl ${
                  badge.type === 'platinum' ? 'bg-purple-100 text-purple-600' :
                  badge.type === 'gold' ? 'bg-amber-100 text-amber-600' :
                  'bg-gray-100 text-gray-600'
                }`}>
                  {BADGE_ICONS[badge.type] && React.createElement(BADGE_ICONS[badge.type], {
                    className: "w-8 h-8"
                  })}
                </div>
                <div className="flex-1">
                  <div className="flex items-center justify-between mb-2">
                    <h4 className="text-lg font-semibold capitalize">{badge.type} Investor</h4>
                    {badge.isUnlocked ? (
                      <span className="px-3 py-1 bg-primary-100 text-primary-700 rounded-full text-sm font-medium">
                        Unlocked
                      </span>
                    ) : (
                      <span className="px-3 py-1 bg-gray-100 text-gray-600 rounded-full text-sm font-medium">
                        {formatProgress(badge.progress)}% Complete
                      </span>
                    )}
                  </div>

                  <div className="grid md:grid-cols-2 gap-6">
                    {/* Requirements */}
                    <div>
                      <h5 className="font-medium mb-3 flex items-center gap-2">
                        <Shield className="w-4 h-4 text-primary-600" />
                        Requirements
                      </h5>
                      <ul className="space-y-2">
                        {Object.entries(badge.requirements).map(([key, value]) => (
                          <li key={key} className={`flex items-center gap-2 text-sm ${
                            badge.requirementsMet.includes(key.toLowerCase()) 
                              ? 'text-primary-600' 
                              : 'text-gray-600'
                          }`}>
                            <div className={`w-1.5 h-1.5 rounded-full ${
                              badge.requirementsMet.includes(key.toLowerCase())
                                ? 'bg-primary-600'
                                : 'bg-gray-400'
                            }`} />
                            {key === 'minPortfolioValue' && `Portfolio Value: $${value.toLocaleString()}`}
                            {key === 'monthlyInvestments' && `${value} Monthly Investments`}
                            {key === 'referrals' && `${value} Successful Referrals`}
                            {key === 'minReturn' && `${value}% Portfolio Returns`}
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Benefits */}
                    <div>
                      <h5 className="font-medium mb-3 flex items-center gap-2">
                        <Gift className="w-4 h-4 text-primary-600" />
                        Benefits
                      </h5>
                      <ul className="space-y-2">
                        {badge.type === 'silver' && [
                          'Early access to new properties',
                          'Reduced platform fees',
                          'Monthly investment insights'
                        ].map((benefit, index) => (
                          <li key={index} className="flex items-center gap-2 text-sm text-gray-600">
                            <div className="w-1.5 h-1.5 rounded-full bg-primary-600" />
                            {benefit}
                          </li>
                        ))}
                        {badge.type === 'gold' && [
                          'Priority investment access',
                          'Exclusive webinars',
                          'Dedicated investment advisor',
                          'Higher referral rewards'
                        ].map((benefit, index) => (
                          <li key={index} className="flex items-center gap-2 text-sm text-gray-600">
                            <div className="w-1.5 h-1.5 rounded-full bg-primary-600" />
                            {benefit}
                          </li>
                        ))}
                        {badge.type === 'platinum' && [
                          'VIP investment opportunities',
                          'Zero platform fees',
                          'Quarterly strategy sessions',
                          'Exclusive networking events',
                          'Custom portfolio analysis'
                        ].map((benefit, index) => (
                          <li key={index} className="flex items-center gap-2 text-sm text-gray-600">
                            <div className="w-1.5 h-1.5 rounded-full bg-primary-600" />
                            {benefit}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>

                  {!badge.isUnlocked && (
                    <div className="mt-6">
                      <div className="w-full h-2 bg-gray-100 rounded-full overflow-hidden">
                        <div 
                          className="h-full bg-primary-600 rounded-full transition-all duration-500"
                          style={{ width: `${formatProgress(badge.progress)}%` }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}