import api from './axios';
import type { ApiResponse } from '@/types/response';
import type { GiftVoucher, CreateVoucherParams, VoucherResponse } from '@/types/voucher';

export const voucherService = {
  async createVoucher(data: CreateVoucherParams): Promise<VoucherResponse> {
    try {
      // Get user ID from local storage
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      if (!user.id) {
        throw new Error('User not found');
      }

      // Add userId to request
      const response = await api.post<VoucherResponse>('/payments/voucher/create-intent', {
        ...data,
        userId: user.id
      });
      return response.data;
    } catch (error) {
      console.error('Create voucher error:', error);
      throw error;
    }
  },

  async getVouchers(): Promise<ApiResponse<GiftVoucher[]>> {
    try {
      const response = await api.get<ApiResponse<GiftVoucher[]>>('/vouchers');
      return response.data;
    } catch (error) {
      console.error('Get vouchers error:', error);
      throw error;
    }
  },

  async getVoucherDetails(code: string): Promise<ApiResponse<GiftVoucher>> {
    try {
      const response = await api.get<ApiResponse<GiftVoucher>>(`/vouchers/${code}`);
      return response.data;
    } catch (error) {
      console.error('Get voucher details error:', error);
      throw error;
    }
  },

  async redeemVoucher(code: string, propertyId: string): Promise<ApiResponse<void>> {
    try {
      const response = await api.post<ApiResponse<void>>(`/vouchers/${code}/redeem`, {
        propertyId
      });
      return response.data;
    } catch (error) {
      console.error('Redeem voucher error:', error);
      throw error;
    }
  }
};