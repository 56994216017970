import React, { useState } from 'react';
import { Gift } from 'lucide-react';
import { GiftVoucherModal } from './GiftVoucherModal';

export function GiftVoucherButton() {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button
        onClick={() => setShowModal(true)}
        className="flex items-center gap-1.5 px-2.5 py-1 bg-amber-50 text-amber-700 rounded-lg hover:bg-amber-100 transition-all border border-amber-200"
      >
        <Gift className="w-3.5 h-3.5" />
        <span className="text-xs font-medium">Gift</span>
      </button>

      <GiftVoucherModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  );
}