import React, { useState, useEffect } from 'react';
import { ReferralCode } from './referral/ReferralCode';
import { ReferralStats } from './referral/ReferralStats';
import { RecentReferrals } from './referral/RecentReferrals';
import { referralService } from '@/services/api/referrals';
import { useAuth } from '@/hooks/useAuth';
import { Gift, Users, Coins, ArrowRight, Share2 } from 'lucide-react';
import toast from 'react-hot-toast';

interface ReferralData {
  referralCode: string;
  total: number;
  completed: number;
  pending: number;
  totalRewards: number;
  recentReferrals: Array<{
    id: string;
    name: string;
    date: string;
    status: 'pending' | 'completed' | 'expired';
    reward: number;
  }>;
}

export function ReferralProgram() {
  const [stats, setStats] = useState<ReferralData | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchReferralData = async () => {
      try {
        const response = await referralService.getReferralHistory();
        console.log('Referral response:', response); // Debug log
        if (response.success && response.data) {
          setStats(response.data);
        } else {
          throw new Error(response.message || 'Failed to load referral data');
        }
      } catch (error) {
        console.error('Referral data error:', error);
        toast.error('Failed to load referral data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchReferralData();
  }, []);

  if (isLoading) {
    return (
      <div className="animate-pulse space-y-8">
        <div className="h-32 bg-gray-200 rounded-xl"></div>
        <div className="grid grid-cols-3 gap-4">
          {[1, 2, 3].map((i) => (
            <div key={i} className="h-24 bg-gray-200 rounded-lg"></div>
          ))}
        </div>
        <div className="h-64 bg-gray-200 rounded-xl"></div>
      </div>
    );
  }

  // Show default state if no stats are available
  const defaultStats = {
    total: 0,
    completed: 0,
    pending: 0,
    totalRewards: 0,
    recentReferrals: []
  };

  const displayStats = stats || defaultStats;

  return (
    <div className="space-y-8">
      {user?.referralCode && (
        <ReferralCode code={user.referralCode} />
      )}

      <ReferralStats 
        stats={{
          total: displayStats.total,
          completed: displayStats.completed,
          pending: displayStats.pending,
          totalRewards: displayStats.totalRewards
        }} 
      />

      <RecentReferrals referrals={displayStats.recentReferrals} />

      <div className="bg-primary-50 p-6 rounded-lg border border-primary-100">
        <h3 className="font-medium text-primary-900 mb-4">How it works</h3>
        <ul className="space-y-3 text-primary-800">
          <li className="flex items-center gap-2">
            1. Share your referral code with friends
          </li>
          <li className="flex items-center gap-2">
            2. Friends sign up using your code
          </li>
          <li className="flex items-center gap-2">
            3. Earn rewards when they make their first investment
          </li>
          <li className="flex items-center gap-2">
            4. Track your referrals and rewards here
          </li>
        </ul>
      </div>

      {/* New Rewards Promotion Section */}
      <div className="grid md:grid-cols-3 gap-6">
        <div className="bg-gradient-to-br from-primary-50 to-primary-100 p-6 rounded-xl border border-primary-200">
          <div className="w-12 h-12 bg-primary-100 rounded-xl flex items-center justify-center mb-4">
            <Gift className="w-6 h-6 text-primary-600" />
          </div>
          <h4 className="text-lg font-semibold text-primary-900 mb-2">
            Instant Rewards
          </h4>
          <p className="text-primary-700">
            Get $100 for every friend who makes their first investment. No limits!
          </p>
        </div>

        <div className="bg-gradient-to-br from-green-50 to-green-100 p-6 rounded-xl border border-green-200">
          <div className="w-12 h-12 bg-green-100 rounded-xl flex items-center justify-center mb-4">
            <Coins className="w-6 h-6 text-green-600" />
          </div>
          <h4 className="text-lg font-semibold text-green-900 mb-2">
            Bonus Tiers
          </h4>
          <p className="text-green-700">
            Unlock higher rewards at 5, 10, and 20 successful referrals!
          </p>
        </div>

        <div className="bg-gradient-to-br from-amber-50 to-amber-100 p-6 rounded-xl border border-amber-200">
          <div className="w-12 h-12 bg-amber-100 rounded-xl flex items-center justify-center mb-4">
            <Users className="w-6 h-6 text-amber-600" />
          </div>
          <h4 className="text-lg font-semibold text-amber-900 mb-2">
            Community Benefits
          </h4>
          <p className="text-amber-700">
            Both you and your friend get exclusive investment opportunities!
          </p>
        </div>
      </div>

      {/* Share Promotion */}
      <div className="bg-gradient-to-r from-primary-600 to-primary-700 text-white p-8 rounded-xl shadow-lg">
        <div className="flex flex-col md:flex-row items-center justify-between gap-6">
          <div>
            <h3 className="text-2xl font-bold mb-2">Ready to Start Earning?</h3>
            <p className="text-primary-100">
              Share your referral code with friends and start earning rewards today!
            </p>
          </div>
          <button
            onClick={() => {
              if (user?.referralCode) {
                navigator.clipboard.writeText(user.referralCode);
                toast.success('Referral code copied!');
              }
            }}
            className="flex items-center gap-2 px-6 py-3 bg-white text-primary-600 rounded-xl font-medium hover:bg-primary-50 transition-all shadow-lg hover:shadow-xl"
          >
            <Share2 className="w-5 h-5" />
            Share Now
            <ArrowRight className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
}