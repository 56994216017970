import React, { useState, useEffect } from 'react';
import { SearchBar } from '../components/SearchBar';
import { PropertyCard } from '../components/property';
import { MapPin, Loader, RefreshCw } from 'lucide-react';
import { propertyService } from '@/services/api/properties';
import type { Property } from '@/types/property';
import toast from 'react-hot-toast';

const locations = [
  "All Locations",
  "San Francisco, CA",
  "Miami Beach, FL",
  "Austin, TX",
  "New York, NY"
];

const propertyTypes = [
  { value: '', label: 'All Properties' },
  { value: 'residential', label: 'Residential' },
  { value: 'commercial', label: 'Commercial' },
  { value: 'mixed_use', label: 'Mixed-Use' },
  { value: 'industrial', label: 'Industrial' }
];

export function PropertyListing() {
  const [properties, setProperties] = useState<Property[]>([]);
  const [selectedLocation, setSelectedLocation] = useState("All Locations");
  const [selectedType, setSelectedType] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const [isRetrying, setIsRetrying] = useState(false);

  const fetchProperties = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const filters = {
        ...(selectedLocation !== "All Locations" && { location: selectedLocation }),
        ...(selectedType && { type: selectedType })
      };

      const response = await propertyService.getProperties(filters);
      
      if (!response.success) {
        throw new Error(response.error || 'Failed to load properties');
      }
      
      setProperties(response.data);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to load properties';
      console.error('Property listing error:', errorMessage);
      setError(errorMessage);
      toast.error(errorMessage);
    } finally {
      setIsLoading(false);
      setIsRetrying(false);
    }
  };

  useEffect(() => {
    fetchProperties();
  }, [selectedLocation, selectedType, retryCount]);

  const handleSearch = async (results: Property[]) => {
    setProperties(results);
  };

  const handleRetry = () => {
    setIsRetrying(true);
    setRetryCount(prev => prev + 1);
  };

  if (isLoading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-center justify-center min-h-[400px]">
          <div className="flex flex-col items-center gap-4">
            <Loader className="w-8 h-8 text-primary-600 animate-spin" />
            <p className="text-gray-600">Loading properties...</p>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-center">
          <p className="text-red-600 mb-4">{error}</p>
          <button
            onClick={handleRetry}
            disabled={isRetrying}
            className="inline-flex items-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700 transition-colors disabled:opacity-50"
          >
            <RefreshCw className={`w-5 h-5 ${isRetrying ? 'animate-spin' : ''}`} />
            {isRetrying ? 'Retrying...' : 'Try Again'}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Investment Properties</h1>
          <p className="text-gray-600">Discover and invest in premium real estate opportunities</p>
        </div>
        <div className="flex items-center gap-4">
          <select 
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value)}
            className="px-4 py-2 rounded-lg border border-gray-200 bg-white text-gray-700 focus:ring-2 focus:ring-primary-500 focus:border-primary-500"
          >
            {propertyTypes.map(type => (
              <option key={type.value} value={type.value}>
                {type.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Location Tabs */}
      <div className="flex items-center gap-2 mb-8 overflow-x-auto pb-2">
        {locations.map((location) => (
          <button
            key={location}
            onClick={() => setSelectedLocation(location)}
            className={`flex items-center gap-2 px-4 py-2 rounded-lg whitespace-nowrap transition-colors ${
              selectedLocation === location
                ? 'bg-primary-600 text-white'
                : 'bg-white text-gray-700 hover:bg-gray-50'
            }`}
          >
            <MapPin className="w-4 h-4" />
            {location}
          </button>
        ))}
      </div>

      {/* Search Bar */}
      <div className="mb-8">
        <SearchBar onSearch={handleSearch} />
      </div>

      {/* Property Grid */}
      {properties.length === 0 ? (
        <div className="text-center py-12">
          <p className="text-gray-600">No properties found matching your criteria.</p>
        </div>
      ) : (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {properties.map((property) => (
            <PropertyCard 
              key={property.id} 
              property={property}
              onInvestmentComplete={fetchProperties}
            />
          ))}
        </div>
      )}
    </div>
  );
}