import React, { useState } from 'react';
import { InvestmentCard } from './InvestmentCard';
import { Pagination } from './Pagination';
import type { Investment } from '@/types/investment';
import type { UserProfile } from '@/types/user';
import { useAuth } from '@/hooks/useAuth';

interface InvestmentListProps {
  investments: Investment[];
  onUpdate?: () => void; // Add this prop
}

const ITEMS_PER_PAGE = 5;

export function InvestmentList({ investments, onUpdate }: InvestmentListProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useAuth();

  // Validate investments array
  if (!Array.isArray(investments)) {
    console.error('Invalid investments prop:', investments);
    return null;
  }

  const totalPages = Math.ceil(investments.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentInvestments = investments.slice(startIndex, endIndex);

  if (investments.length === 0) {
    return (
      <div className="text-center py-8 text-gray-600">
        <p>No investments found</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="space-y-4">
        {currentInvestments.map((investment) => (
          user && <InvestmentCard 
            key={investment.id} 
            investment={investment}
            user={user}
            onUpdate={onUpdate} // Pass the onUpdate prop
          />
        ))}
      </div>

      {totalPages > 1 && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      )}
    </div>
  );
}