import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  Lock, User, Bell, HelpCircle, LogOut, Settings, 
  Key, Shield, Menu, X, Copy, Check, Gift 
} from 'lucide-react';
import { WalletConnect } from './WalletConnect';
import { ContactSupport } from './ContactSupport';
import { GiftVoucherButton } from './voucher/GiftVoucherButton';
import { useAuth } from '@/hooks/useAuth';
import { getSignedUrl } from '@/utils/s3';
import toast from 'react-hot-toast';

const navigationItems = [
  { path: '/dashboard', label: 'Dashboard', icon: Shield },
  { path: '/properties', label: 'Properties', icon: Lock },
  { path: '/rewards', label: 'Rewards', icon: Key }
];

export function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [showSupport, setShowSupport] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [copied, setCopied] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const { user, logout } = useAuth();
  const userMenuRef = useRef<HTMLDivElement>(null);

  const isActive = (path: string) => {
    return location.pathname === path;
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (userMenuRef.current && !userMenuRef.current.contains(event.target as Node)) {
        setShowUserMenu(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const loadAvatarUrl = async () => {
      if (!user?.avatar) {
        setAvatarUrl(null);
        return;
      }

      if (user.avatar.includes('amazonaws.com')) {
        try {
          const signedUrl = await getSignedUrl(user.avatar);
          setAvatarUrl(signedUrl);
        } catch (error) {
          console.error('Failed to get signed URL for avatar:', error);
          setAvatarUrl(null);
        }
      } else {
        setAvatarUrl(user.avatar);
      }
    };

    loadAvatarUrl();
  }, [user?.avatar]);

  const handleCopyReferralCode = async () => {
    if (!user?.referralCode) return;
    
    try {
      await navigator.clipboard.writeText(user.referralCode);
      setCopied(true);
      toast.success('Referral code copied!');
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      toast.error('Failed to copy code');
    }
  };

  return (
    <nav className="fixed w-full z-50 bg-white border-b border-primary-100 shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Left side */}
          <div className="flex items-center gap-8">
            <Link to="/" className="flex items-center gap-2">
              <Lock className="w-6 h-6 text-primary-600" />
              <span className="text-xl font-bold text-gradient-blue">
                Sociallocket
              </span>
            </Link>

            <div className="hidden md:flex items-center gap-6">
              {navigationItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`nav-link ${
                    isActive(item.path) 
                      ? 'nav-link-active' 
                      : 'nav-link-inactive'
                  }`}
                >
                  <item.icon className="w-5 h-5" />
                  <span>{item.label}</span>
                </Link>
              ))}
            </div>
          </div>

          {/* Right side */}
          <div className="flex items-center gap-4">
            {user && <GiftVoucherButton />}

            {user?.referralCode && (
              <button
                onClick={handleCopyReferralCode}
                className="hidden md:flex items-center gap-2 px-3 py-1.5 bg-primary-50 text-primary-600 rounded-lg hover:bg-primary-100 transition-colors"
              >
                {copied ? (
                  <>
                    <Check className="w-4 h-4" />
                    Copied!
                  </>
                ) : (
                  <>
                    <Copy className="w-4 h-4" />
                    {user.referralCode}
                  </>
                )}
              </button>
            )}

            <button
              onClick={() => setShowSupport(true)}
              className="p-2 text-gray-600 hover:text-primary-600 hover:bg-primary-50 rounded-lg transition-colors"
            >
              <HelpCircle className="w-5 h-5" />
            </button>

            {user ? (
              <div className="relative" ref={userMenuRef}>
                <button
                  onClick={() => setShowUserMenu(!showUserMenu)}
                  className="flex items-center gap-2 p-2 rounded-lg hover:bg-primary-50 transition-colors"
                >
                  {avatarUrl ? (
                    <img
                      src={avatarUrl}
                      alt={user.name}
                      className="w-8 h-8 rounded-full ring-2 ring-primary-100 object-cover"
                    />
                  ) : (
                    <div className="w-8 h-8 rounded-full bg-primary-100 flex items-center justify-center">
                      <User className="w-4 h-4 text-primary-600" />
                    </div>
                  )}
                </button>

                {showUserMenu && (
                  <div className="absolute right-0 mt-2 w-56 bg-white rounded-xl border border-primary-100 shadow-blue">
                    <div className="p-4 border-b border-primary-100">
                      <p className="font-medium">{user.name}</p>
                      <p className="text-sm text-gray-600">{user.email}</p>
                    </div>
                    <div className="py-2">
                      <Link
                        to="/profile"
                        className="flex items-center gap-2 px-4 py-2 text-gray-700 hover:bg-primary-50"
                      >
                        <User className="w-4 h-4" />
                        Profile
                      </Link>
                      <Link
                        to="/settings"
                        className="flex items-center gap-2 px-4 py-2 text-gray-700 hover:bg-primary-50"
                      >
                        <Settings className="w-4 h-4" />
                        Settings
                      </Link>
                      <button
                        onClick={logout}
                        className="w-full flex items-center gap-2 px-4 py-2 text-red-600 hover:bg-red-50"
                      >
                        <LogOut className="w-4 h-4" />
                        Logout
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <Link
                to="/login"
                className="btn-primary"
              >
                Sign In
              </Link>
            )}
            <WalletConnect />

            {/* Mobile Menu Button */}
            <button
              onClick={() => setShowMobileMenu(!showMobileMenu)}
              className="md:hidden p-2 text-gray-600 hover:text-primary-600 hover:bg-primary-50 rounded-lg"
            >
              {showMobileMenu ? (
                <X className="w-6 h-6" />
              ) : (
                <Menu className="w-6 h-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {showMobileMenu && (
          <div className="md:hidden py-4 border-t border-primary-100">
            <div className="space-y-2">
              {navigationItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`nav-link ${
                    isActive(item.path)
                      ? 'nav-link-active'
                      : 'nav-link-inactive'
                  }`}
                  onClick={() => setShowMobileMenu(false)}
                >
                  <item.icon className="w-5 h-5" />
                  <span>{item.label}</span>
                </Link>
              ))}
              {user?.referralCode && (
                <button
                  onClick={handleCopyReferralCode}
                  className="w-full flex items-center gap-2 px-4 py-2 text-primary-600 hover:bg-primary-50"
                >
                  {copied ? <Check className="w-4 h-4" /> : <Copy className="w-4 h-4" />}
                  {copied ? 'Copied!' : user.referralCode}
                </button>
              )}
            </div>
          </div>
        )}
      </div>

      <ContactSupport isOpen={showSupport} onClose={() => setShowSupport(false)} />
    </nav>
  );
}