import { stripeService } from './payments/stripe';
import { cryptoService } from './payments/crypto';
import type { ApiResponse } from '@/types/response';
import type { Investment } from '@/types/investment';
import type { PaymentIntent, RecurringPaymentDetails } from '@/types/payment';

interface CreatePaymentIntentParams {
  amount: number;
  propertyId: string;
  method: 'stripe' | 'crypto';
  isRecurring?: boolean;
  // Voucher-specific fields
  recipientName?: string;
  recipientEmail?: string;
  message?: string;
}

interface PaymentConfirmation {
  investment: Investment;
  paymentId: string;
  subscriptionId?: string;
}

// Create a type for the payment service that includes all stripe methods
interface PaymentService {
  createPaymentIntent: (params: CreatePaymentIntentParams) => Promise<ApiResponse<PaymentIntent>>;
  processCryptoPayment: typeof cryptoService.processCryptoPayment;
  verifyTransaction: typeof cryptoService.verifyTransaction;
  stripe: {
    getSubscriptionDetails: typeof stripeService.getSubscriptionDetails;
    updateSubscriptionAmount: typeof stripeService.updateSubscriptionAmount;
    pauseSubscription: typeof stripeService.pauseSubscription;
    resumeSubscription: typeof stripeService.resumeSubscription;
    cancelSubscription: typeof stripeService.cancelSubscription;
  };
}

// Export the payment service with proper typing
export const paymentService: PaymentService = {
  createPaymentIntent: stripeService.createPaymentIntent,
  processCryptoPayment: cryptoService.processCryptoPayment,
  verifyTransaction: cryptoService.verifyTransaction,
  stripe: {
    getSubscriptionDetails: stripeService.getSubscriptionDetails,
    updateSubscriptionAmount: stripeService.updateSubscriptionAmount,
    pauseSubscription: stripeService.pauseSubscription,
    resumeSubscription: stripeService.resumeSubscription,
    cancelSubscription: stripeService.cancelSubscription
  }
};

// Export additional types
export type { CreatePaymentIntentParams, PaymentConfirmation, PaymentIntent, RecurringPaymentDetails };