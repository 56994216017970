import React from 'react';
import { BookOpen, Youtube, Globe, ArrowRight, PlayCircle, FileText, Newspaper, HelpCircle } from 'lucide-react';

const resources = [
  {
    title: 'Blog',
    description: 'Stay updated with market insights and investment tips',
    icon: Newspaper,
    items: [
      {
        title: 'The Future of Real Estate Investment: Crypto and Tokenization',
        description: 'Discover how real estate investing is more accessible than ever.',
        link: 'https://sociallocket.com/blog/1'
      },
      {
        title: 'Why Fractional Real Estate is the Next Big Thing',
        description: 'Latest insights on market movements and opportunities',
        link: 'https://sociallocket.com/blog/2'
      }
    ]
  },
  {
    title: 'Knowledge Base',
    description: 'Comprehensive guides and documentation',
    icon: FileText,
    items: [
      {
        title: 'Investment Process Guide',
        description: 'Step-by-step guide to investing on our platform',
        link: 'https://sociallocket.com/blog/4'
      },
      {
        title: '5 Tips for Building a Diverse Real Estate Portfolio',
        description: 'What to look for when evaluating properties',
        link: 'https://sociallocket.com/blog/3'
      }
    ]
  },
  {
    title: 'Video Tutorials',
    description: 'Learn through our comprehensive video guides',
    icon: PlayCircle,
    items: [
      {
        title: 'Platform Walkthrough',
        description: 'Get familiar with our investment platform',
        link: 'https://sociallocket.com/blog/5'
      },
      {
        title: 'Investment Strategy Series',
        description: 'Expert tips on building your portfolio',
        link: 'https://sociallocket.com/blog/6'
      }
    ]
  }
];

export function ResourcesSection() {
  return (
    <div className="space-y-8">
      <div>
        <h3 className="text-lg font-medium mb-2">Learning Resources</h3>
        <p className="text-gray-600">
          Explore our comprehensive resources to enhance your investment knowledge.
        </p>
      </div>

      <div className="grid gap-8">
        {resources.map((section) => (
          <div key={section.title} className="space-y-4">
            <div className="flex items-center gap-3">
              <div className="p-2 rounded-lg bg-primary-50">
                <section.icon className="w-5 h-5 text-primary-600" />
              </div>
              <div>
                <h4 className="font-medium">{section.title}</h4>
                <p className="text-sm text-gray-600">{section.description}</p>
              </div>
            </div>

            <div className="grid md:grid-cols-2 gap-4">
              {section.items.map((item) => (
                <a
                  key={item.title}
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group p-4 rounded-xl border border-gray-200 hover:border-primary-300 transition-all"
                >
                  <div className="flex justify-between items-start">
                    <div>
                      <h5 className="font-medium mb-1 group-hover:text-primary-600">
                        {item.title}
                      </h5>
                      <p className="text-sm text-gray-600">
                        {item.description}
                      </p>
                    </div>
                    <ArrowRight className="w-5 h-5 text-gray-400 group-hover:text-primary-600 transform group-hover:translate-x-1 transition-all" />
                  </div>
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>

      {/* Quick Links */}
      <div className="mt-8 pt-8 border-t border-gray-200">
        <h4 className="font-medium mb-4">Quick Links</h4>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <a
            href="https://sociallocket.com/blog"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center gap-2 p-3 rounded-lg border border-gray-200 hover:border-primary-300 hover:bg-primary-50 transition-all group"
          >
            <Globe className="w-5 h-5 text-primary-600" />
            <span className="font-medium group-hover:text-primary-600">Blog</span>
          </a>
          <a
            href="https://sociallocket.com/blog"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center gap-2 p-3 rounded-lg border border-gray-200 hover:border-primary-300 hover:bg-primary-50 transition-all group"
          >
            <BookOpen className="w-5 h-5 text-primary-600" />
            <span className="font-medium group-hover:text-primary-600">Docs</span>
          </a>
          <a
            href="https://youtube.com/@Sociallocket"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center gap-2 p-3 rounded-lg border border-gray-200 hover:border-primary-300 hover:bg-primary-50 transition-all group"
          >
            <Youtube className="w-5 h-5 text-primary-600" />
            <span className="font-medium group-hover:text-primary-600">Videos</span>
          </a>
          <a
            href="https://sociallocket.com/contact"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center gap-2 p-3 rounded-lg border border-gray-200 hover:border-primary-300 hover:bg-primary-50 transition-all group"
          >
            <HelpCircle className="w-5 h-5 text-primary-600" />
            <span className="font-medium group-hover:text-primary-600">Help</span>
          </a>
        </div>
      </div>
    </div>
  );
}