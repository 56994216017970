console.log("hello from value")
import React from 'react';
import { Wallet, TrendingUp, Building2 } from 'lucide-react';
import { investmentValueService } from '@/services/api/investments/value';
import type { Investment } from '@/types/investment';

interface DashboardStatsProps {
  investments: Investment[];
}

export function DashboardStats({ investments }: DashboardStatsProps) {
  // Calculate total invested amount
  const totalInvested = investments.reduce((sum, inv) => {
    const amount = typeof inv.amount === 'string' 
      ? parseFloat(inv.amount) 
      : Number(inv.amount) || 0;
    return sum + amount;
  }, 0);

  // Calculate total current value
  const totalCurrentValue = investments.reduce((sum, inv) => {
    const currentValue = typeof inv.currentValue === 'string'
      ? parseFloat(inv.currentValue)
      : Number(inv.currentValue) || Number(inv.amount) || 0;
    return sum + currentValue;
  }, 0);

  // Calculate portfolio return
  const portfolioReturn = totalInvested > 0
    ? ((totalCurrentValue - totalInvested) / totalInvested) * 100
    : 0;

  // Calculate returns for each investment
  const returns = investments.map(inv => {
    const initialAmount = Number(inv.amount) || 0;
    const currentValue = Number(inv.currentValue) || initialAmount;
    return initialAmount > 0 
      ? ((currentValue - initialAmount) / initialAmount) * 100 
      : 0;
  });

  // Calculate average return
  const averageReturn = returns.length > 0
    ? returns.reduce((sum, ret) => sum + ret, 0) / returns.length
    : 0;

  // Format currency
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };

  // Format percentage with sign
  const formatPercentage = (value: number) => {
    const sign = value >= 0 ? '+' : '';
    return `${sign}${value.toFixed(1)}%`;
  };

  return (
    <div className="grid grid-cols-3 gap-6 mb-8">
      <div className="p-6 rounded-xl bg-gray-50 border border-gray-100">
        <div className="flex items-center gap-4">
          <Wallet className="w-6 h-6 text-primary-600" />
          <div>
            <div className="text-sm text-gray-600">Portfolio Value</div>
            <div className="text-2xl font-bold">{formatCurrency(totalCurrentValue)}</div>
            <div className="text-sm text-gray-500">
              Initial: {formatCurrency(totalInvested)}
            </div>
          </div>
        </div>
      </div>

      <div className="p-6 rounded-xl bg-gray-50 border border-gray-100">
        <div className="flex items-center gap-4">
          <TrendingUp className="w-6 h-6 text-green-600" />
          <div>
            <div className="text-sm text-gray-600">Portfolio Return</div>
            <div className="text-2xl font-bold text-green-600">
              {formatPercentage(portfolioReturn)}
            </div>
            <div className="text-sm text-gray-500">
              Avg: {formatPercentage(averageReturn)}
            </div>
          </div>
        </div>
      </div>

      <div className="p-6 rounded-xl bg-gray-50 border border-gray-100">
        <div className="flex items-center gap-4">
          <Building2 className="w-6 h-6 text-primary-600" />
          <div>
            <div className="text-sm text-gray-600">Active Investments</div>
            <div className="text-2xl font-bold">{investments.length}</div>
            <div className="text-sm text-gray-500">
              Properties
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}