import type { Investment } from '@/types/investment';

interface BadgeProgress {
  type: 'silver' | 'gold' | 'platinum';
  progress: number;
  isUnlocked: boolean;
  unlockedAt: string | null;
  requirements: {
    minInvestment: number;
    monthlyInvestments: number;
    minPortfolioValue: number;
    referrals?: number;
    minReturn?: number;
  };
  requirementsMet: string[];
}

const BADGE_REQUIREMENTS = {
  silver: {
    minInvestment: 10000,
    monthlyInvestments: 3,
    minPortfolioValue: 10000
  },
  gold: {
    minInvestment: 50000,
    monthlyInvestments: 6,
    minPortfolioValue: 50000,
    referrals: 2
  },
  platinum: {
    minInvestment: 100000,
    monthlyInvestments: 12,
    minPortfolioValue: 100000,
    referrals: 5,
    minReturn: 15
  }
} as const;

function calculatePortfolioMetrics(investments: Investment[]) {
  const portfolioValue = investments.reduce((sum, inv) => sum + Number(inv.currentValue), 0);
  const maxInvestment = Math.max(...investments.map(inv => Number(inv.amount)), 0);
  const avgReturn = investments.length > 0
    ? investments.reduce((sum, inv) => sum + Number(inv.returnToDate), 0) / investments.length
    : 0;

  // Calculate monthly investments (last 30 days)
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  const monthlyInvestments = investments.filter(inv => 
    new Date(inv.createdAt) > thirtyDaysAgo
  ).length;

  return {
    portfolioValue,
    maxInvestment,
    avgReturn,
    monthlyInvestments
  };
}

function calculateBadgeRequirements(
  metrics: ReturnType<typeof calculatePortfolioMetrics>,
  completedReferrals: number,
  requirements: typeof BADGE_REQUIREMENTS[keyof typeof BADGE_REQUIREMENTS]
) {
  const requirementsMet: string[] = [];
  let totalRequirements = 3; // Base requirements for all badges

  // Check portfolio value
  if (metrics.portfolioValue >= requirements.minPortfolioValue) {
    requirementsMet.push('portfolio_value');
  }

  // Check monthly investments
  if (metrics.monthlyInvestments >= requirements.monthlyInvestments) {
    requirementsMet.push('monthly_investments');
  }

  // Check max investment
  if (metrics.maxInvestment >= requirements.minInvestment) {
    requirementsMet.push('min_investment');
  }

  // Check referrals if required
  if ('referrals' in requirements) {
    totalRequirements++;
    if (completedReferrals >= (requirements.referrals || 0)) {
      requirementsMet.push('referrals');
    }
  }

  // Check minimum return if required
  if ('minReturn' in requirements) {
    totalRequirements++;
    if (metrics.avgReturn >= (requirements.minReturn || 0)) {
      requirementsMet.push('min_return');
    }
  }

  // Calculate progress percentage and round to exactly 1 decimal place
  const rawProgress = (requirementsMet.length / totalRequirements) * 100;
  const roundedProgress = Number((Math.min(rawProgress, 100)).toFixed(1));

  return {
    requirementsMet,
    progress: roundedProgress,
    isUnlocked: roundedProgress === 100
  };
}

export function calculateBadgeProgress(investments: Investment[], completedReferrals: number): BadgeProgress[] {
  const metrics = calculatePortfolioMetrics(investments);

  return Object.entries(BADGE_REQUIREMENTS).map(([type, requirements]) => {
    const { requirementsMet, progress, isUnlocked } = calculateBadgeRequirements(
      metrics,
      completedReferrals,
      requirements
    );

    return {
      type: type as BadgeProgress['type'],
      progress,
      isUnlocked,
      unlockedAt: isUnlocked ? new Date().toISOString() : null,
      requirements,
      requirementsMet
    };
  });
}