import React from 'react';
import { InvestmentList } from './InvestmentList';
import { InvestmentFilters } from '../InvestmentFilters';
import type { InvestmentSectionProps } from './types';

export function InvestmentSection({
  investments,
  user,
  searchTerm,
  setSearchTerm,
  selectedType,
  setSelectedType,
  sortBy,
  setSortBy,
  onUpdate // Add this prop
}: InvestmentSectionProps) {
  return (
    <div className="bg-white rounded-xl border border-gray-200">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 p-6">
        <h3 className="text-lg font-semibold">My Investments</h3>
        <InvestmentFilters
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          sortBy={sortBy}
          setSortBy={setSortBy}
        />
      </div>

      <div className="p-6 pt-0">
        <InvestmentList
          investments={investments}
          onUpdate={onUpdate} // Pass the onUpdate prop
        />
      </div>
    </div>
  );
}