import React from 'react';
import { RefreshCw, Clock, AlertCircle } from 'lucide-react';
import type { RecurringPayment } from '@/types/payment';

interface RecurringPaymentBadgeProps {
  payment: RecurringPayment;
  onClick?: () => void;
}

export function RecurringPaymentBadge({ payment, onClick }: RecurringPaymentBadgeProps) {
  const statusColors = {
    active: 'bg-green-100 text-green-800',
    paused: 'bg-amber-100 text-amber-800',
    cancelled: 'bg-red-100 text-red-800'
  };

  const statusIcons = {
    active: RefreshCw,
    paused: Clock,
    cancelled: AlertCircle
  };

  const Icon = statusIcons[payment.status];

  return (
    <button
      onClick={onClick}
      className={`px-2 py-1 text-xs font-medium rounded-full flex items-center gap-1 ${
        statusColors[payment.status]
      } hover:opacity-80 transition-opacity`}
    >
      <Icon className="w-3 h-3" />
      <span>Monthly • {payment.status}</span>
    </button>
  );
}