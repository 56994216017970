console.log("hello from me")
import React from 'react';
import { Building2 } from 'lucide-react';
import type { Property } from '@/types/property';

interface PropertyDescriptionProps {
  property: Property;
}

export function PropertyDescription({ property }: PropertyDescriptionProps) {
  console.log('Property data in description:', {
    features: property.features,
    amenities: property.amenities
  });

  return (
    <div className="bg-white p-8 rounded-xl border border-gray-200 mb-8">
      <h2 className="text-xl font-semibold mb-4">About this property</h2>
      <p className="text-gray-600 mb-6">{property.description.full}</p>

      {property.features?.length > 0 && (
        <>
          <h3 className="font-semibold mb-4">Key Features</h3>
          <div className="grid grid-cols-2 gap-4 mb-6">
            {property.features.map((feature, index) => (
              <div key={index} className="flex items-center gap-2">
                <Building2 className="w-5 h-5 text-primary-600" />
                <span>{feature}</span>
              </div>
            ))}
          </div>
        </>
      )}

      {property.amenities?.length > 0 && (
        <>
          <h3 className="font-semibold mb-4">Amenities</h3>
          <div className="grid grid-cols-2 gap-4">
            {property.amenities.map((amenity, index) => (
              <div key={index} className="flex items-center gap-2">
                <Building2 className="w-5 h-5 text-primary-600" />
                <span>{amenity}</span>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}