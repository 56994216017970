import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '@/hooks/useAuth';
import { useWatchlist } from '@/hooks/useWatchlist';
import { propertyService } from '@/services/api/properties';
import { documentService } from '@/services/api/properties/documents';
import { voucherService } from '@/services/api/vouchers';
import { PropertyHeader } from '../components/property/details/PropertyHeader';
import { PropertyProgress } from '../components/property/details/PropertyProgress';
import { PropertyStats } from '../components/property/details/PropertyStats';
import { PropertyDescription } from '../components/property/details/PropertyDescription';
import { PropertyDetailsGallery } from '../components/PropertyDetailsGallery';
import { PropertyDocuments } from '../components/PropertyDocuments';
import { PropertyMap } from '../components/PropertyMap';
import { InvestmentCalculator } from '../components/InvestmentCalculator';
import { InvestmentModal } from '../components/investment/InvestmentModal';
import { StickyCTA } from '../components/StickyCTA';
import { SideInvestmentCard } from '../components/SideInvestmentCard';
import { AgentProfile } from '../components/AgentProfile';
import toast from 'react-hot-toast';
import type { Property } from '@/types/property';
import type { Document } from '@/types/document';

const agent = {
  id: "1",
  name: "Sarah Chen",
  title: "Senior Investment Advisor",
  image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?auto=format&fit=crop&w=150&q=80",
  phone: "+971 (58) 546-8775",
  email: "team@sociallocket.com",
  experience: 8,
  deals: 150,
  rating: 4.7,
  bio: "Specializing in luxury residential properties with 8+ years of experience in real estate investment advisory.",
  availability: 'available' as const,
  languages: ['English'],
  specialties: ['Luxury Properties', 'Investment Portfolio Management', 'International Investments']
};

export function PropertyDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { isWatchlisted, toggleWatchlist } = useWatchlist();
  const [property, setProperty] = useState<Property | null>(null);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showInvestModal, setShowInvestModal] = useState(false);

  const fetchPropertyData = async () => {
    if (!id) return;

    try {
      setIsLoading(true);
      const [propertyResponse, documentsResponse] = await Promise.all([
        propertyService.getProperty(id),
        documentService.getDocuments(id)
      ]);

      if (propertyResponse.success && propertyResponse.data) {
        setProperty(propertyResponse.data);
      }

      if (documentsResponse.success) {
        setDocuments(documentsResponse.data || []);
      }
    } catch (error) {
      console.error('Failed to load property:', error);
      toast.error('Failed to load property details');
      navigate('/properties');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPropertyData();
  }, [id]);

  const handleInvest = async (amount: number, method: 'stripe' | 'crypto' | 'voucher') => {
    if (!isAuthenticated) {
      toast.error('Please log in to invest');
      navigate('/login');
      return;
    }

    try {
      if (method === 'voucher') {
        // Handle voucher redemption
        const voucherCode = localStorage.getItem('voucherCode');
        if (!voucherCode) {
          throw new Error('No voucher code found');
        }

        await voucherService.redeemVoucher(voucherCode, property!.id);
        localStorage.removeItem('voucherCode'); // Clear the code after use
        toast.success('Voucher redeemed successfully');
        setShowInvestModal(false);
        fetchPropertyData(); // Refresh property data
      } else {
        // Handle stripe/crypto payments
        const response = await propertyService.submitInvestment(property!.id, amount, method);
        if (!response.success) {
          throw new Error(response.message || 'Failed to submit investment');
        }
        toast.success('Investment submitted successfully');
        setShowInvestModal(false);
        fetchPropertyData(); // Refresh property data
      }
    } catch (error) {
      console.error('Investment error:', error);
      toast.error(error instanceof Error ? error.message : 'Failed to submit investment');
    }
  };

  const handleWatchlistClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    
    if (!isAuthenticated) {
      toast.error('Please log in to save properties');
      navigate('/login');
      return;
    }

    if (!property) return;

    try {
      if (isWatchlisted(property.id)) {
        await propertyService.removeFromWatchlist(property.id);
      } else {
        await propertyService.saveProperty(property.id);
      }
      toggleWatchlist(property);
      toast.success(isWatchlisted(property.id) ? 'Removed from watchlist' : 'Added to watchlist');
    } catch (error) {
      toast.error('Failed to update watchlist');
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="animate-pulse space-y-8">
            <div className="h-8 bg-gray-200 rounded w-1/3"></div>
            <div className="h-96 bg-gray-200 rounded-xl"></div>
            <div className="grid grid-cols-3 gap-6">
              {[1, 2, 3].map((i) => (
                <div key={i} className="h-24 bg-gray-200 rounded-lg"></div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!property) {
    return (
      <div className="min-h-screen bg-gray-50 pt-16 flex items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">Property Not Found</h1>
          <button
            onClick={() => navigate('/properties')}
            className="text-primary-600 hover:text-primary-700 font-medium"
          >
            Browse Properties
          </button>
        </div>
      </div>
    );
  }

  const progress = (property.raisedAmount / property.targetAmount) * 100;

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Main Content */}
          <div className="flex-1">
            <div className="bg-white p-8 rounded-xl border border-gray-200 mb-8">
              <PropertyHeader 
                property={property}
                isInWatchlist={isWatchlisted(property.id)}
                onWatchlistClick={handleWatchlistClick}
              />

              <PropertyDetailsGallery
                images={property.images}
                title={property.title}
                className="mb-6"
              />

              <PropertyProgress property={property} progress={progress} />
              <PropertyStats property={property} />
            </div>

            <PropertyDescription property={property} />

            <div className="bg-white p-8 rounded-xl border border-gray-200 mb-8">
              <h2 className="text-xl font-semibold mb-6">Property Documents</h2>
              <PropertyDocuments
                propertyId={id!}
                documents={documents}
              />
            </div>

            <InvestmentCalculator
              expectedReturn={property.expectedReturn}
              minInvestment={property.minInvestment}
            />

            <div className="mt-8">
              <PropertyMap
                latitude={property.location.coordinates.latitude}
                longitude={property.location.coordinates.longitude}
                address={property.location.address}
              />
            </div>
          </div>

          {/* Sidebar */}
          <div className="lg:w-96">
            <div className="lg:sticky lg:top-24 space-y-8">
              <SideInvestmentCard
                property={property}
                progress={progress}
                onInvest={() => {
                  if (!isAuthenticated) {
                    toast.error('Please log in to invest');
                    navigate('/login');
                    return;
                  }
                  setShowInvestModal(true);
                }}
              />

              <AgentProfile agent={agent} />
            </div>
          </div>
        </div>
      </div>

      <StickyCTA
        property={property}
        progress={progress}
        onInvest={() => {
          if (!isAuthenticated) {
            toast.error('Please log in to invest');
            navigate('/login');
            return;
          }
          setShowInvestModal(true);
        }}
      />

      {showInvestModal && (
        <InvestmentModal
          isOpen={showInvestModal}
          onClose={() => setShowInvestModal(false)}
          property={property}
          onInvest={handleInvest}
        />
      )}
    </div>
  );
}