import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PortfolioChart } from '../components/PortfolioChart';
import { RewardsSection } from '../components/RewardsSection';
import { DashboardStats } from '../components/dashboard/DashboardStats';
import { DashboardHeader } from '../components/dashboard/DashboardHeader';
import { InvestmentSection } from '../components/dashboard/investments/InvestmentSection';
import { WatchlistSection } from '../components/dashboard/WatchlistSection';
import { LoadingState } from '../components/dashboard/LoadingState';
import { ErrorState } from '../components/dashboard/ErrorState';
import { EmptyState } from '../components/dashboard/EmptyState';
import { useInvestments } from '../hooks/useInvestments';
import { useAuth } from '@/hooks/useAuth';
import { verificationService } from '@/services/api/verification';
import { investmentValueService } from '@/services/api/investments/value';
import { Shield, ArrowRight, Clock, AlertTriangle } from 'lucide-react';
import toast from 'react-hot-toast';

const BATCH_SIZE = 3; // Process 3 investments at a time
const BATCH_DELAY = 2000; // 2 seconds between batches

export function Dashboard() {
  const { user } = useAuth();
  const [verificationStatus, setVerificationStatus] = useState<'unverified' | 'pending' | 'verified' | 'rejected' | null>(null);
  const { 
    investments, 
    isLoading, 
    error,
    filteredInvestments,
    searchTerm,
    setSearchTerm,
    selectedType,
    setSelectedType,
    sortBy,
    setSortBy,
    refreshInvestments
  } = useInvestments();

  // Fetch verification status
  useEffect(() => {
    const fetchVerificationStatus = async () => {
      try {
        const response = await verificationService.getStatus();
        if (response.success && response.data) {
          setVerificationStatus(response.data.status);
        }
      } catch (error) {
        console.error('Failed to fetch verification status:', error);
      }
    };

    if (user) {
      fetchVerificationStatus();
    }
  }, [user]);

  // Update investment values when dashboard loads
  useEffect(() => {
    const updateInvestmentValues = async () => {
      if (!investments.length) return;

      try {
        // Process investments in batches
        for (let i = 0; i < investments.length; i += BATCH_SIZE) {
          const batch = investments.slice(i, i + BATCH_SIZE);
          
          // Update each investment in the current batch
          await Promise.all(
            batch.map(investment => 
              investmentValueService.updateInvestmentValue(investment.id)
            )
          );

          // Wait before processing next batch
          if (i + BATCH_SIZE < investments.length) {
            await new Promise(resolve => setTimeout(resolve, BATCH_DELAY));
          }
        }
        
        // Refresh investments to get updated values
        await refreshInvestments();
      } catch (error) {
        console.error('Failed to update investment values:', error);
        toast.error('Failed to update some investment values');
      }
    };

    updateInvestmentValues();
  }, [investments.length]); // Only run when investments array length changes

  if (isLoading) {
    return <LoadingState />;
  }

  if (error) {
    return <ErrorState message={error} />;
  }

  const renderVerificationBanner = () => {
    if (!verificationStatus || verificationStatus === 'verified') return null;

    switch (verificationStatus) {
      case 'unverified':
        return (
          <div className="mb-8 bg-amber-50 border border-amber-200 rounded-xl p-6">
            <div className="flex items-start gap-4">
              <div className="p-3 bg-amber-100 rounded-xl">
                <Shield className="w-6 h-6 text-amber-600" />
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold text-amber-900 mb-2">
                  Identity Verification Required
                </h3>
                <p className="text-amber-700 mb-4">
                  Complete identity verification to unlock full platform features and investment opportunities. This helps us maintain a secure and compliant investment environment.
                </p>
                <Link
                  to="/settings/security/verify"
                  className="inline-flex items-center gap-2 px-4 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-700 transition-colors"
                >
                  Start Verification
                  <ArrowRight className="w-5 h-5" />
                </Link>
              </div>
            </div>
          </div>
        );

      case 'pending':
        return (
          <div className="mb-8 bg-blue-50 border border-blue-200 rounded-xl p-6">
            <div className="flex items-start gap-4">
              <div className="p-3 bg-blue-100 rounded-xl">
                <Clock className="w-6 h-6 text-blue-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-blue-900 mb-2">
                  Verification In Progress
                </h3>
                <p className="text-blue-700">
                  Your identity verification documents are being reviewed. This process typically takes 1-2 business days. We'll notify you once the review is complete.
                </p>
              </div>
            </div>
          </div>
        );

      case 'rejected':
        return (
          <div className="mb-8 bg-red-50 border border-red-200 rounded-xl p-6">
            <div className="flex items-start gap-4">
              <div className="p-3 bg-red-100 rounded-xl">
                <AlertTriangle className="w-6 h-6 text-red-600" />
              </div>
              <div className="flex-1">
                <h3 className="text-lg font-semibold text-red-900 mb-2">
                  Verification Update Required
                </h3>
                <p className="text-red-700 mb-4">
                  Your identity verification was not approved. Please review the feedback and submit updated documents.
                </p>
                <Link
                  to="/settings/security/verify"
                  className="inline-flex items-center gap-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
                >
                  Update Verification
                  <ArrowRight className="w-5 h-5" />
                </Link>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {renderVerificationBanner()}

      <div className="flex flex-col lg:flex-row gap-8">
        {/* Main Content */}
        <div className="flex-1">
          <div className="bg-white p-6 rounded-xl border border-gray-200">
            <DashboardHeader />

            {investments.length === 0 ? (
              <EmptyState />
            ) : (
              <>
                <DashboardStats investments={investments} />

                <div className="mb-8">
                  <PortfolioChart data={investments} />
                </div>

                <InvestmentSection
                  investments={filteredInvestments}
                  user={user}
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  selectedType={selectedType}
                  setSelectedType={setSelectedType}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  onUpdate={refreshInvestments}
                />
              </>
            )}
          </div>
        </div>

        {/* Right Sidebar */}
        <div className="md:w-96">
          <div className="sticky top-24 space-y-8">
            <RewardsSection />
            <WatchlistSection />
          </div>
        </div>
      </div>
    </div>
  );
}