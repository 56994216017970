import React, { useState } from 'react';
import { Clock, DollarSign, CreditCard, Calendar, Pause, Play, X } from 'lucide-react';
import { paymentService } from '@/services/api/payments';
import type { RecurringPayment } from '@/types/payment';
import toast from 'react-hot-toast';

interface RecurringPaymentDetailsProps {
  payment: RecurringPayment;
  onClose: () => void;
  onUpdate: () => void;
}

export function RecurringPaymentDetails({ payment, onClose, onUpdate }: RecurringPaymentDetailsProps) {
  const [isUpdating, setIsUpdating] = useState(false);

  const handlePauseResume = async () => {
    try {
      setIsUpdating(true);
      if (payment.status === 'active') {
        await paymentService.stripe.pauseSubscription(payment.subscriptionId);
        toast.success('Payments paused successfully');
      } else {
        await paymentService.stripe.resumeSubscription(payment.subscriptionId);
        toast.success('Payments resumed successfully');
      }
      onUpdate();
    } catch (error) {
      toast.error(payment.status === 'active' ? 'Failed to pause payments' : 'Failed to resume payments');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCancel = async () => {
    const confirmed = window.confirm(
      'Are you sure you want to cancel recurring payments? This action cannot be undone.'
    );
    if (!confirmed) return;

    try {
      setIsUpdating(true);
      await paymentService.stripe.cancelSubscription(payment.subscriptionId);
      toast.success('Recurring payments cancelled successfully');
      onUpdate();
    } catch (error) {
      toast.error('Failed to cancel recurring payments');
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50">
      <div className="bg-white rounded-xl max-w-md w-full">
        <div className="p-6">
          <div className="flex justify-between items-start mb-6">
            <h3 className="text-lg font-semibold">Recurring Payment Details</h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <X className="w-5 h-5" />
            </button>
          </div>

          <div className="space-y-6">
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="flex items-center gap-3 mb-4">
                <div className="p-2 bg-primary-50 rounded-lg">
                  <CreditCard className="w-5 h-5 text-primary-600" />
                </div>
                <div>
                  <div className="font-medium">Payment Method</div>
                  <div className="text-sm text-gray-600">
                    {payment.paymentMethod.details.brand.toUpperCase()} ending in{' '}
                    {payment.paymentMethod.details.last4}
                  </div>
                </div>
              </div>

              <div className="space-y-3">
                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Status</span>
                  <span className={`font-medium ${
                    payment.status === 'active' ? 'text-green-600' :
                    payment.status === 'paused' ? 'text-amber-600' :
                    'text-red-600'
                  }`}>
                    {payment.status.charAt(0).toUpperCase() + payment.status.slice(1)}
                  </span>
                </div>

                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Monthly Amount</span>
                  <span className="font-medium">${payment.amount.toLocaleString()}</span>
                </div>

                <div className="flex justify-between text-sm">
                  <span className="text-gray-600">Next Payment</span>
                  <span className="font-medium">
                    {new Date(payment.nextPaymentDate).toLocaleDateString()}
                  </span>
                </div>

                {payment.lastPaymentDate && (
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Last Payment</span>
                    <span className="font-medium">
                      {new Date(payment.lastPaymentDate).toLocaleDateString()}
                    </span>
                  </div>
                )}
              </div>
            </div>

            <div className="flex gap-4">
              {payment.status !== 'cancelled' && (
                <>
                  <button
                    onClick={handlePauseResume}
                    disabled={isUpdating}
                    className="flex-1 flex items-center justify-center gap-2 px-4 py-2 bg-amber-600 text-white rounded-lg hover:bg-amber-700 transition-colors disabled:opacity-50"
                  >
                    {payment.status === 'active' ? (
                      <>
                        <Pause className="w-5 h-5" />
                        Pause Payments
                      </>
                    ) : (
                      <>
                        <Play className="w-5 h-5" />
                        Resume Payments
                      </>
                    )}
                  </button>

                  <button
                    onClick={handleCancel}
                    disabled={isUpdating}
                    className="flex-1 flex items-center justify-center gap-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors disabled:opacity-50"
                  >
                    <X className="w-5 h-5" />
                    Cancel Subscription
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}